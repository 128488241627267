import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import Dropzone from "react-dropzone";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/auth-context";
import axios, { url } from "../utils/axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import helpers from "../utils/helpers";
import moment from "moment";
import { jsPDF } from 'jspdf';
const TableRow = ({
  item,
  key,
  setRateConId,
  deleteModalToggler,
  setRateCons,
  token
}) => {
  const history = useHistory();
  return (
    <tr key={key}>
      <td className="align-middle">
        {item.completed && (
          <i className="fa-regular fa-check-circle text-success"></i>
        )}
      </td>
      <td className="align-middle">
        {moment(item.createdAt).format("MM/DD/YYYY")}
      </td>
      <td className="align-middle">{item.courierHandler.name}</td>
      <td className="align-middle">{item.courierHandler.loadNumber}</td>
      <td className="align-middle">
        {item.user.name} {item.user.l_name || null}
      </td>
      <td className="align-middle">
        <Button
          className="btn_light"
          style={{ borderRadius: "0px" }}
          onClick={() => {
            history.push(
              "/" +
              item.courierHandler.name +
              "/" +
              item.courierHandler.loadNumber +
              "?from=dashboard"
            );
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            window.open(
              "/" +
              item.courierHandler.name +
              "/" +
              item.courierHandler.loadNumber +
              "?from=dashboard",
              "_blank"
            );
          }}
        >
          Track
        </Button>
      </td>
      <td className="align-middle">
        <i
          className="fa-sharp fa-solid fa-eye-slash"
          style={{
            fontSize: "25px",
            cursor: "pointer",
          }}
          onClick={async () => {
            await axios
              .get("/rate-con/" + item.id, {
                headers: {
                  Authorization: `Token ${token}`,
                },
              })
              .then((resp) => {
                setRateCons((prev) =>
                  prev.filter((itm) => itm.id !== item.id)
                );
              })
              .catch((err) => {
                toast.error(err.response?.data?.message || err.message);
              });
            // setInProcess(true)
            // setRateCons((prev) => [...prev, prev.filter((itm) => itm.id === item.id)]);
            // setInProcess(false)

          }}
        ></i>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <i
          className="fa-sharp fa-solid fa-circle-xmark"
          style={{
            color: "#b32929",
            fontSize: "25px",
            cursor: "pointer",
          }}
          onClick={() => {
            setRateConId(item.id);
            deleteModalToggler();
          }}
        ></i>
      </td>
    </tr>
  );
};
const assignRateConValidator = (values) => {
  const errors = {};

  if (values.broker === "") {
    errors.broker = "This field is required.";
  }

  if (values.loadNumber === "") {
    errors.loadNumber = "This field is required.";
  }

  if (values.driver === "") {
    errors.driver = "This field is required.";
  }

  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValid = pattern.test(values.email);

  if (values.email !== "" && !isValid) {
    errors.email = "Email is invalid.";
  }

  if (values.email2 !== "" && !isValid) {
    errors.email2 = "Email is invalid.";
  }
  if (values.email3 !== "" && !isValid) {
    errors.email3 = "Email is invalid.";
  }
  if (values.email4 !== "" && !isValid) {
    errors.email4 = "Email is invalid.";
  }
  if (values.email5 !== "" && !isValid) {
    errors.email5 = "Email is invalid.";
  }

  return errors;
};

const CarrierDashboard = () => {
  const [no_of_emails, setno_of_emails] = useState(1);
  const [send_files_to_email, setsend_files_to_emails] = useState(true);
  const [merge_rate_and_bol, set_merge_rate_and_bol] = useState(true);
  const [manual_rate, set_manual_rate] = useState(false);
  const [pickup_no_bool, set_pickup_no_bool] = useState(false);
  const [notes, set_notes] = useState('');
  const { token, user, logout } = useAuth();

  const [dragEntered, setDragEntered] = useState(false);

  const [drivers, setDrivers] = useState([]);
  const [uploaded, setUploaded] = useState('');
  const [count, setcount] = useState(0);

  const [rateCons, setRateCons] = useState([]);
  const [rateConId, setRateConId] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const deleteModalToggler = () => setDeleteModal((prev) => !prev);

  const history = useHistory();
  // const [pickup_date, set_pickup_date] = useState(new Date())
  const [pickup_address, set_pickup_address] = useState('')
  const [pickup_time, set_pickup_time] = useState('')
  const [destination_address, set_destination_address] = useState('')
  const [delivery_time, set_delivery_time] = useState('')
  const [pickup_no, set_pickup_no] = useState('')
  const [weight, set_weight] = useState('')
  const [commodity, set_commodity] = useState('')
  const [rate, set_rate] = useState('')

  const handle_ratecon = async () => {
    // let pickup = new Date(pickup_time)

    // console.log(pickup_time.toLocaleString())
    const doc = new jsPDF();
    const centerX = doc.internal.pageSize.getWidth() / 2;

    // Add the title "Rate Sheet" at the center
    doc.setFontSize(24);
    doc.text('Rate Sheet', centerX, 20, 'center');

    // Define your data
    const data = [
      { item: 'Pickup address:', value: pickup_address },
      { item: 'Pickup time:', value: pickup_time?.toLocaleString() },
      { item: 'Destination address', value: destination_address },
      { item: 'Delivery Time', value: delivery_time?.toLocaleString() },
      { item: 'Pickup Number', value: pickup_no },
      { item: 'Call for pickup number', value: pickup_no_bool ? "Yes" : "No" },
      { item: 'Weight', value: weight },
      { item: 'Commodity', value: commodity },
      { item: 'Rate', value: rate },

    ];

    // Add the structured data
    doc.setFontSize(12);
    let yPosition = 40;
    data.forEach((item) => {
      doc.text(item.item, 10, yPosition);
      doc.text(item.value, 80, yPosition);
      yPosition += 10;
    });
    const pdfBlob = await doc.output('blob');
    console.log(pdfBlob)
    try {
      const formData = new FormData();
      formData.append('image', pdfBlob, 'rate.pdf');

      const resp = await axios
        .post("/upload/rate-con", formData, {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => resp.data);
      console.log('rsponse,', resp)
      setUploaded(resp.filename);

      console.log('PDF file successfully sent to the server.');
    } catch (error) {
      console.error('Error sending PDF file to the server:', error);
    }
  }


  if (user.role === "user") {
    logout();
    toast.error("You are not authorized to access this resource.");
    history.push("/carrier-login");
  }
  const sorting = (a, b) => {

    if (a.createdAt > b.createdAt) {
      return -1
    } else if (b.createdAt > a.createdAt) {
      return 1
    } else {
      return 0
    }
  }
  useEffect(() => {
    const getRateCons = async () => {
      try {
        const resp = await axios
          .get("/rate-con", {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((resp) => resp.data);
        setRateCons(resp);
      } catch (error) {
        toast.error(error.response?.data?.message || error?.message);
      }
    };

    getRateCons();
  }, []);

  useEffect(() => {
    const getDrivers = async () => {
      const resp = await axios
        .get("/driver/" + user?.mcNumber, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((resp) => resp.data);
      setDrivers(resp);
    };

    getDrivers();
  }, []);
  const [dropdown, setDropdown] = useState(false)
  return (
    <>
      <Modal isOpen={deleteModal}>
        <ModalHeader
          toggle={() => {
            setRateConId("");
            deleteModalToggler();
          }}
        >
          Delete Rate Con?
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete?</p>
          <div>
            <Button
              onClick={() => {
                setRateConId("");
                deleteModalToggler();
              }}
            >
              Cancel
            </Button>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <Button
              onClick={async () => {
                await axios
                  .delete("/rate-con/" + rateConId, {
                    headers: {
                      Authorization: `Token ${token}`,
                    },
                  })
                  .then((resp) => {
                    setRateCons((prev) =>
                      prev.filter((itm) => itm.id !== rateConId)
                    );
                  })
                  .catch((err) => {
                    toast.error(err.response?.data?.message || err.message);
                  });
                setRateConId("");
                deleteModalToggler();
              }}
              color="danger"
            >
              Delete
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div className="p-3 py-5">
        <div
          style={{
            maxWidth: "900px",
            width: "100%",
            margin: "0px auto",
          }}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                // position: "absolute",
                // top: "20px",
                // left: "50px",
              }}
            >
              <span className="fa-solid fa-arrow-left"></span> Go Back
            </Link >
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              alignItems: 'center'
            }}>
              <span className="d-flex" style={{ color: '#be1f2d', position: 'relative', cursor: 'pointer' }} onClick={() => setDropdown(prev => !prev)}>
                {user.user.assigned_count}/{user.user.limit} loads
              </span>
              <span className="d-flex" style={{ color: '#be1f2d', position: 'relative', cursor: 'pointer' }} onClick={() => setDropdown(prev => !prev)}>
                Menu
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    alignItems: 'center',
                    gap: "10px",
                    display: `${dropdown ? 'flex' : 'none'}`,
                    position: 'absolute',
                    top: '100%',
                    right: '0%',
                    boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
                    width: '150px',
                    background: 'white',
                    padding: '10px'
                  }}>
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      // position: "absolute",
                      // top: "20px",
                      // right: "50px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                    }}
                  >
                    <i className="fa-solid fa-power-off"></i> Logout
                  </Link>

                  <Link
                    to="/drivers"
                    style={{
                      textDecoration: "none",
                      // position: "absolute",
                      // top: "20px",
                      // right: "150px",
                    }}
                  >
                    Drivers
                  </Link>
                  <Link
                    to="/change-password"
                    style={{
                      textDecoration: "none",
                      // position: "absolute",
                      // top: "20px",
                      // right: "240px",
                    }}
                  >
                    Change password
                  </Link>
                  <Link
                    to="/upgrade"
                    style={{
                      textDecoration: "none",
                      // position: "absolute",
                      // top: "20px",
                      // right: "240px",
                    }}
                  >
                    Upgrade
                  </Link>
                </div>
              </span>
            </div>
            {/* <div
              className="d-none d-md-flex"
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center',
                gap: "16px",

              }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  // position: "absolute",
                  // top: "20px",
                  // right: "50px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
              >
                <i className="fa-solid fa-power-off"></i> Logout
              </Link>

              <Link
                to="/drivers"
                style={{
                  textDecoration: "none",
                  // position: "absolute",
                  // top: "20px",
                  // right: "150px",
                }}
              >
                Drivers
              </Link>
              <Link
                to="/change-password"
                style={{
                  textDecoration: "none",
                  // position: "absolute",
                  // top: "20px",
                  // right: "240px",
                }}
              >
                Change password
              </Link>
              <a
                href="javascript:;"
                to="/change-password"
                style={{
                  textDecoration: "none",
                  // position: "absolute",
                  // top: "20px",
                  // right: "240px",
                }}
              >
                {user.user.assigned_count}/{user.user.limit} loads
              </a>
              <Link
                to="/upgrade"
                style={{
                  textDecoration: "none",
                  // position: "absolute",
                  // top: "20px",
                  // right: "240px",
                }}
              >
                Upgrade
              </Link>
            </div> */}
          </div>
          <h3 className="text-center mb-5" style={{ fontWeight: "bold" }}>
            Assign load to a driver
          </h3>
          <Formik
            initialValues={{
              broker: "",
              loadNumber: "",
              driver: "",
              email: "",
              email2: "",
              email3: "",
              email4: "",
              email5: "",
            }}
            validate={assignRateConValidator}
            onSubmit={async (values) => {
              // if (!uploaded) {
              try {
                const resp = await axios
                  .post(
                    "/rate-con",
                    { ...values, document: uploaded, send_files_to_email, merge_rate_and_bol, notes, mcNumber: user.mcNumber },
                    {
                      headers: {
                        Authorization: `Token ${token}`,
                      },
                    }
                  )
                  .then((resp) => resp.data);
                setRateCons((prev) => [resp, ...prev]);
                let new_user = {
                  mcNumber: user.mcNumber,
                  token: user.token,
                  role: user.role,
                  user: { ...user.user, assigned_count: user.user.assigned_count + 1 }
                }
                localStorage.setItem("user", JSON.stringify(new_user));
                window.location.reload()
              } catch (error) {
                toast.error(error.response?.data?.message || error.message);
              }
              // } else {
              //   toast.error("Upload document.");
              // }
            }}
          >
            {({ handleSubmit, errors, getFieldProps }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="d-flex gap-3">
                  <FormGroup className="w-100">
                    <Input
                      placeholder="Enter name of broker"
                      className="form-ctrl"
                      {...getFieldProps("broker")}
                    />
                    {errors.broker && (
                      <small className="text-danger">{errors.broker}</small>
                    )}
                  </FormGroup>
                  <FormGroup className="w-100">
                    <Input
                      placeholder="Enter load number"
                      className="form-ctrl"
                      {...getFieldProps("loadNumber")}
                    />
                    {errors.loadNumber && (
                      <small className="text-danger">{errors.loadNumber}</small>
                    )}
                  </FormGroup>
                  <FormGroup className="w-100">
                    <Input
                      type="select"
                      placeholder="Select driver"
                      className="form-ctrl"
                      {...getFieldProps("driver")}
                    >
                      <option value="">Select driver</option>
                      {drivers.length > 0 &&
                        drivers.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {helpers.capitalize(item.name)}{" "}
                              {item.l_name
                                ? helpers.capitalize(item.l_name)
                                : null}{" "}
                              {item.phone ? item.phone : null}
                            </option>
                          );
                        })}
                    </Input>
                    {errors.driver && (
                      <small className="text-danger">{errors.driver}</small>
                    )}
                  </FormGroup>

                </div>
                <div className="d-flex gap-3" style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                  {/* <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                    <Input
                      placeholder="Delivery date"
                      className="form-ctrl"
                      {...getFieldProps("email")}
                    />
                    {errors.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </FormGroup> */}
                  {no_of_emails > 0 &&
                    <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                      <Input
                        placeholder="Enter email of broker"
                        className="form-ctrl"
                        {...getFieldProps("email")}
                      />
                      {errors.email && (
                        <small className="text-danger">{errors.email}</small>
                      )}
                    </FormGroup>
                  }{no_of_emails > 1 &&

                    <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                      <Input
                        placeholder="Enter 2nd email"
                        className="form-ctrl"
                        {...getFieldProps("email2")}
                      />
                      {errors.email2 && (
                        <small className="text-danger">{errors.email2}</small>
                      )}
                    </FormGroup>
                  }{no_of_emails > 2 &&
                    <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                      <Input
                        placeholder="Enter 3rd email"
                        className="form-ctrl"
                        {...getFieldProps("email3")}
                      />
                      {errors.email3 && (
                        <small className="text-danger">{errors.email3}</small>
                      )}
                    </FormGroup>
                  }{no_of_emails > 3 &&
                    <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                      <Input
                        placeholder="Enter 4th email"
                        className="form-ctrl"
                        {...getFieldProps("email4")}
                      />
                      {errors.email4 && (
                        <small className="text-danger">{errors.email4}</small>
                      )}
                    </FormGroup>
                  }{no_of_emails > 4 &&
                    <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                      <Input
                        placeholder="Enter 5th email"
                        className="form-ctrl"
                        {...getFieldProps("email5")}
                      />
                      {errors.email5 && (
                        <small className="text-danger">{errors.email5}</small>
                      )}
                    </FormGroup>
                  }
                  <button type="button" class="btn btn-primary" style={{ whiteSpace: 'nowrap', marginBottom: '1rem', width: '32%', backgroundColor: '#be1f2d', border: 'none' }} onClick={() => setno_of_emails(prev => prev + 1)}>Add Email</button>
                  {/* <FormGroup className="w-100">
                    <Input
                      placeholder=""
                      disabled
                      style={{ border: "none" }}
                      className="form-ctrl"
                    />
                  </FormGroup>
                  <FormGroup className="w-100">
                    <Input
                      placeholder=""
                      disabled
                      style={{ border: "none" }}
                      className="form-ctrl"
                    />
                  </FormGroup> */}
                </div>
                <textarea placeholder="Add driver notes" rows={4} style={{ width: '340px', padding: "10px" }} value={notes} onChange={e => set_notes(e.target.value)} />
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  margin: 'auto',
                  width: '360px'
                }}>
                  {/* <div class="form-check" style={{ width: 'fit-content', marginTop: '10px' }}>
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={merge_rate_and_bol} onChange={() => set_merge_rate_and_bol(prev => !prev)} />
                    <label className="form-check-label" for="flexCheckDefault">
                      Merge rate confirmation with BOL when sending to broker / dispatcher
                    </label>
                  </div>
                  <div class="form-check" style={{ width: 'fit-content', marginTop: '10px' }}>
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={send_files_to_email} onChange={() => setsend_files_to_emails(prev => !prev)} />
                    <label className="form-check-label" for="flexCheckDefault">
                      Send BOL to above emails on completion
                    </label>
                  </div> */}
                  <div class="form-check" style={{ width: 'fit-content', marginTop: '10px' }}>
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={manual_rate} onChange={() => set_manual_rate(prev => !prev)} />
                    <label className="form-check-label" for="flexCheckDefault">
                      Manually enter rate confirmation data
                    </label>
                  </div>
                </div>
                {/* <form action="https://dev.truckerfox.com/api/upload/rate-con" method="post" enctype="multipart/form-data" style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
                  {uploaded.length === 0 && <>
                    <input type="file" name="image" multiple id="selectme" style={{ display: 'none' }} />
                    <div style={{ cursor: 'pointer' }} onClick={() => document.getElementById('selectme').click()}>
                      <img
                        src="/upload.png"
                        alt="Not found"
                        width="75px"
                      />
                    </div>
                  </>
                  }
                  <p>Upload Rate Con</p>
                  {count != 0 && <p>{count} file's selected</p>}
                  <Button className="btn_light" type="submit">
                    Upload Rate-Con files
                  </Button>
                </form> */}
                {!manual_rate &&
                  <div>
                    {!uploaded && (
                      <Dropzone
                        multiple={true}
                        onDrop={async (files) => {
                          console.log('to be uploaded,', files)
                          setDragEntered(false);
                          const nonPdfFiles = files.filter(file => file.type !== 'application/pdf');
                          if (nonPdfFiles.length > 0) {
                            alert('Only PDF files are allowed!');
                            return;
                          }
                          const formData = new FormData();
                          files.forEach((file, index) => {
                            const newFileName = file.name.replaceAll('#', ''); // Replace 'newName.jpg' with the desired name
                            const newFile = new File([file], newFileName, { type: file.type });
                            formData.append(`image`, newFile);
                          });
                          const resp = await axios
                            .post("/upload/rate-con", formData, {
                              headers: {
                                Authorization: `Token ${token}`,
                                "Content-Type": "multipart/form-data",
                              },
                            })
                            .then((resp) => resp.data);
                          console.log('rsponse,', resp)
                          setUploaded(resp.filename);
                        }}
                        onDragEnter={() => {
                          setDragEntered(true);
                        }}
                        onDragLeave={() => {
                          setDragEntered(false);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps({
                              className:
                                "d-flex flex-column align-items-center my-4",
                              style: {
                                border: dragEntered
                                  ? "1px dashed lightgreen"
                                  : "none",
                                backgroundColor: dragEntered
                                  ? "#efefef"
                                  : "white",
                                borderRadius: "10px",
                              },
                            })}
                          >
                            <input {...getInputProps()} />
                            <div>
                              <img
                                src="/upload.png"
                                alt="Not found"
                                width="75px"
                              />
                            </div>
                            <p>Upload Rate Con</p>
                          </div>
                        )}
                      </Dropzone>
                    )}

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
                      {uploaded && (
                        <div>
                          <p className="text-center">
                            <i
                              className="fa-sharp fa-solid fa-circle-xmark"
                              style={{
                                color: "rgb(179, 41, 41)",
                                fontSize: "25px;",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                setUploaded();
                              }}
                            ></i>
                          </p>
                          <p className="text-center">
                            <i
                              className="fa-solid fa-file"
                              style={{ fontSize: "50px", color: "#1974D2" }}
                            ></i>
                          </p>
                          <p className="text-center">{uploaded}</p>
                        </div>
                      )}
                    </div>
                  </div>
                }

                {manual_rate &&
                  <>
                    {!uploaded && <>
                      <div className="d-flex gap-3" style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                        <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                          <Input
                            placeholder="Pick-up adddress"
                            className="form-ctrl"
                            value={pickup_address}
                            onChange={e => set_pickup_address(e.target.value)}
                          />
                        </FormGroup>
                        {/* <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                          <Input
                            placeholder="Pick-up Time"
                            className="form-ctrl"
                            value={pickup_time}
                            onChange={e => set_pickup_time(e.target.value)}
                          />
                        </FormGroup> */}
                        <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                          <Input
                            placeholder="Destination address"
                            className="form-ctrl"
                            value={destination_address}
                            onChange={e => set_destination_address(e.target.value)}
                          />
                        </FormGroup>
                        {/* <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                          <Input
                            placeholder="Delivery time"
                            className="form-ctrl"
                            value={delivery_time}
                            onChange={e => set_delivery_time(e.target.value)}
                          />
                        </FormGroup> */}
                        <div class="form-check" style={{ width: 'fit-content', marginBottom: '20px' }}>
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={pickup_no_bool} onChange={() => set_pickup_no_bool(prev => !prev)} />
                          <label className="form-check-label" for="flexCheckDefault">
                            Call for pickup numebr
                          </label>
                        </div>
                        <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                          <Input
                            placeholder="Pick-up #"
                            className="form-ctrl"
                            value={pickup_no}
                            onChange={e => set_pickup_no(e.target.value)}
                          />
                        </FormGroup>

                        <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                          <Input
                            placeholder="Weight"
                            className="form-ctrl"
                            value={weight}
                            onChange={e => set_weight(e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                          <Input
                            placeholder="Commodity"
                            className="form-ctrl"
                            value={commodity}
                            onChange={e => set_commodity(e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup className="w-100" style={{ maxWidth: '32%' }}>
                          <Input
                            placeholder="Rate"
                            className="form-ctrl"
                            value={rate}
                            onChange={e => set_rate(e.target.value)}
                          />
                        </FormGroup>
                      </div>
                      <div className="d-flex flex-column mb-3" style={{ width: '340px' }}>
                        <p style={{ marginBottom: '0px', fontSize: '14px', marginTop: '10px' }}>Pickup Date and Time:</p>
                        <DateTimePicker
                          minDate={new Date()}
                          onChange={set_pickup_time} value={pickup_time}
                        />
                      </div>
                      <div className="d-flex flex-column mb-3" style={{ width: '340px' }}>
                        <p style={{ marginBottom: '0px', fontSize: '14px', marginTop: '10px' }}>Delivery Date and Time:</p>
                        <DateTimePicker
                          minDate={new Date()}
                          onChange={set_delivery_time} value={delivery_time}
                        />
                      </div>
                      <div className="d-flex justify-content-center m-auto mb-2">
                        <Button className="btn_light" onClick={handle_ratecon}>
                          Save RateCon
                        </Button>
                      </div>
                    </>
                    }
                    {uploaded && (
                      <div>
                        <p className="text-center">
                          <i
                            className="fa-sharp fa-solid fa-circle-xmark"
                            style={{
                              color: "rgb(179, 41, 41)",
                              fontSize: "25px;",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              setUploaded();
                            }}
                          ></i>
                        </p>
                        <p className="text-center">
                          <i
                            className="fa-solid fa-file"
                            style={{ fontSize: "50px", color: "#1974D2" }}
                          ></i>
                        </p>
                        <p className="text-center">{uploaded}</p>
                      </div>
                    )}
                  </>
                }

                <div className="d-flex justify-content-center m-auto">
                  <Button className="btn_light" type="submit">
                    Assign
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <h3 className="text-center my-5" style={{ fontWeight: "bold" }}>
            Assigned loads
          </h3>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Date Created</th>
                <th>Broker</th>
                <th>Load Number</th>
                <th>Driver</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {rateCons.length > 0 ?

                (
                  rateCons.sort((a, b) => sorting(a, b)).filter(item => !item.hidden).map((item, index) => {
                    return (
                      <TableRow
                        item={item}
                        key={index}
                        setRateCons={setRateCons}
                        setRateConId={setRateConId}
                        deleteModalToggler={deleteModalToggler}
                        token={token}
                      />
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      No data to display
                    </td>
                  </tr>
                )}
            </tbody>
          </Table>
        </div >
      </div >
    </>
  );
};

export default CarrierDashboard;
